<template>
	<div class="page-content no-padding flex flex-column lg:flex-row lg:align-items-stretch" :class="{'has-tree': category?.extra_data.level > 0}">
        <div class="categories-tree lg:w-4" v-if="category?.extra_data.level > 0">
            <Tree :value="categoriesTree" :expandedKeys="expandedTreeKeys">
                <template #default="slotProps">
                    <div @click="goToCategory(slotProps.node.data.slug)" class="flex flex-row items-center category-tree-node" :class="{'current-category': slotProps.node.data.id === category?.id}">
                        <img v-if="slotProps.node.data.extra_data?.icon_type === 'icon' && slotProps.node.data.extra_data?.icon" :src="`https://api.iconify.design/${iconUri(slotProps.node.data.extra_data?.icon, 0)}/${iconUri(slotProps.node.data.extra_data?.icon, 1)}.svg?color=%23${slotProps.node.data.extra_data?.icon_color ?? '6366F1'}`" alt="" class="mr-2">
                        <img v-if="slotProps.node.data.extra_data?.icon_type === 'image' && slotProps.node.data.extra_data?.icon" :src="getIconImageUrl(slotProps.node.data.id)" alt="" class="mr-2">
                        <span>{{ slotProps.node.label }}</span>
                    </div>
                </template>
            </Tree>
        </div>
        <div class="grid no-height align-self-start" :class="{'lg:w-8': category?.extra_data.level > 0}">
            <div class="col-12" v-if="category?.extra_data.level < 1 && category?.subcategories && category?.subcategories.length > 0">
                <div class="grid py-4 px-4">
                    <div v-for="subc in category.subcategories" :key="subc.id" class="col-12 md:col-6 lg:col-4">
                        <div :class="`column-button category-button items-start ${$appState.estate.extra_data.button_type}`" @click="goToCategory(subc.slug)">
                            <img v-if="subc.extra_data?.icon_type === 'icon' && subc.extra_data?.icon" :src="`https://api.iconify.design/${iconUri(subc.extra_data?.icon, 0)}/${iconUri(subc.extra_data?.icon, 1)}.svg?color=%23${subc.extra_data?.icon_color ?? '6366F1'}`" alt="">
                            <img v-if="subc.extra_data?.icon_type === 'image' && subc.extra_data?.icon" :src="getIconImageUrl(subc.id)" alt="">
                            <div class="button-wrapper">
                                <h5>{{ subc.name }}</h5>
                                <h6 v-if="subc.short_desc">{{ subc.short_desc }}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="content-list" class="col-12 grid imageGallery" v-if="category?.isGallery">
                <div class="col-12 mb-2">
                    <h5 class="text-center">{{ category?.name }}</h5>
                </div>
                <DataView :value="images" layout="grid" :paginator="true" :rows="8" :rowsPerPageOptions="[8,12,16,20,24]">
                    <template #grid="slotProps">
                        <div class="col-12 md:col-3">
                            <Image :src="downloadUrl(slotProps.data.id)" :alt="slotProps.data.title" :preview="true" imageClass="galleryImg" />
                            <p class="text-center font-medium mt-1">{{ slotProps.data.title }}</p>
                        </div>
                    </template>
                </DataView>
            </div>
            <div :id="!category?.isGallery ? 'content-list' : ''" class="col-12" v-if="(!category?.isGallery || (category?.isGallery && documents.length > 0)) && category?.extra_data.level > 0">
                <DataTable :value="documents" v-model:expandedRows="expandedRows" :paginator="true" :rowsPerPageOptions="[10,15,20,25,50]" class="p-datatable-gridlines" :rows="10" dataKey="id" :rowHover="true" :loading="loading" responsiveLayout="scroll" @page="pageChanged" @row-click="rowClick" sortField="title" :sortOrder="1">
                    <template #header>
                        <div class="table-header flex flex-column md:flex-row md:justify-content-between">
                            <h5 class="mb-2 md:m-0 md:align-self-center">{{ category?.name }}</h5>
                            <div class="flex flex-column md:flex-row">
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search" />
                                    <InputText v-model="searchQuery" placeholder="Buscar..." />
                                </span>
                            </div>
                        </div>
                    </template>
                    <template #empty>
                        No hay documentos.
                    </template>
                    <template #loading>
                        Cargando datos, espera por favor...
                    </template>
                    <Column :expander="true" headerStyle="width: 3rem" />
                    <Column header="Título" field="title" style="min-width: 20rem;" valign="center" :sortable="true">
                        <template #body="{data}">
                            <div class="flex flex-row">
                                <iconify-icon :icon="documentService.getDocumentIcon(data.mime_type)" class="mr-2" style="font-size: 1.8em;"></iconify-icon>
                                {{data.title}}
                            </div>
                        </template>
                    </Column>
                    <template #expansion="{data}">
                        <div style="padding-left: 6rem;">
                            <p>{{data.description}}</p>
                            <p>
                                <a v-if="!isPdf(data.mime_type) || (isPdf(data.mime_type) && canDownload(data.extra_data))" :href="downloadUrl(data.id)" target="_blank" class="p-button mr-3"><iconify-icon icon="mdi:download" class="mr-2" style="font-size: 1.8em;"></iconify-icon> Descargar documento</a>
                                <a v-if="isPdf(data.mime_type)" :href="viewPdfUrl(data.id)" target="_blank" class="p-button"><iconify-icon :icon="documentService.getDocumentIcon(data.mime_type)" class="mr-2" style="font-size: 1.8em;"></iconify-icon> Ver documento</a>
                            </p>
                        </div>
                    </template>
                </DataTable>
            </div>
        </div>
    </div>
</template>

<script>
import RouteGuardService from '@/service/RouteGuardService.js';
import ApiService from '@/service/ApiService.js';
import AuthService from '@/service/AuthService.js';
import CategoryService from '@/service/CategoryService.js';
import DocumentService from '@/service/DocumentService.js';
import ErrorReportService from '@/service/ErrorReportService.js';

const compUrl = '/categories';
const imageMimeTypes = [
    'image/avif',
    'image/bmp',
    'image/gif',
    'image/jpeg',
    'image/png',
    'image/svg+xml',
    'image/tiff',
    'image/webp',
];

export default {
    beforeRouteEnter(to) {
        let goTo = RouteGuardService.isLoggedIn();

        if (goTo !== true) {
            window.sessionStorage.setItem('afterLogin', `${compUrl}/${to.params.slug}`);
        }

        return goTo;
    },
    data() {
        return {
            categoriesTree: null,
            expandedTreeKeys: {},
            rootCategory: null,
            category: null,
            documents: null,
            images: null,
            expandedRows: [],
            page: 1,
            loading: true,
            query: '',
            qTimeout: null,
        }
    },
    authService: null,
    categoryService: null,
    documentService: null,
    categorySlug: null,
    errorReportService: null,
    emits: ['updateBreadcrumbs'],
    created() {
        this.authService = new AuthService();
        this.categoryService = new CategoryService();
        this.documentService = new DocumentService();
        this.errorReportService = new ErrorReportService();

        if (!this.$route.params.slug) {
            this.$watch(
                () => this.$route.params,
                (params) => {
                    this.categorySlug = params.slug;
                },
            )
        } else {
            this.categorySlug = this.$route.params.slug;
        }
    },
    mounted() {
        if (!this.$appState.estate) {
            this.$watch(
                () => this.$appState.estate,
                () => {
                    this.fetchCategoryDocuments();
                },
            );
        } else {
            this.fetchCategoryDocuments();
        }
    },
    computed: {
        searchQuery: {
            get() {
                return this.query;
            },
            set(value) {
                if (this.qTimeout) {
                    clearTimeout(this.qTimeout);
                }
                this.qTimeout = setTimeout(() => {
                    this.query = value;

                    if (this.query.length >= 3 || this.query.length === 0) {
                        this.fetchCategoryDocuments();
                    }
                }, 500);
            }
        },
    },
    methods: {
        formatDate(value) {
            return value.toLocaleDateString('es-ES', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
            });
        },
        fetchCategoryDocuments() {
            this.loading = true;

            if (!this.categoriesTree) {
                this.categoryService.fetchCategoriesTree(this.$appState.estate.id, false, '').then(response => {
                    this.rootCategory = {
                        id: response.data.categories[0].id,
                        name: response.data.categories[0].name,
                        slug: response.data.categories[0].slug,
                        short_desc: response.data.categories[0].short_desc,
                        extra_data: response.data.categories[0].extra_data
                    };
                    this.expandedTreeKeys[response.data.categories[0].id] = true;
                    let cs = [{
                        key: response.data.categories[0].id,
                        label: response.data.categories[0].name,
                        data: {
                            id: response.data.categories[0].id,
                            slug: response.data.categories[0].slug,
                            name: response.data.categories[0].name,
                            short_desc: response.data.categories[0].short_desc,
                            extra_data: response.data.categories[0].extra_data,
                            created_at: new Date(response.data.categories[0].created_at)
                        },
                        children: []
                    }];
                    this.buildTree(cs[0].children, response.data.categories[0]);
                    this.categoriesTree = cs;
                    this.fetchCategoryDocuments();
                }).catch(error => {
                    console.error(error);
                    this.errorReportService.sendReport(
                        this.$appState.visitorId,
                        window.navigator.userAgent,
                        this.$appState.estate.id,
                        this.errorReportService.getRequestData(error.request),
                        this.errorReportService.getErrorData(error.response),
                    );
                    this.loading = false;
                    this.$toast.add({severity:'error', summary: 'Error', detail: 'C-01: No se ha podido obtener las categorías', life: 3000});
                });
            } else {
                this.categoryService.fetchCategoryDocuments(this.$appState.estate.id, this.categorySlug).then(response => {
                    this.category = response.data.category;
                    this.$appState.newDocumentCategory = {};
                    this.$appState.newDocumentCategory[response.data.category.id] = true;
                    this.loading = false;
                    response.data.documents.forEach(document => document.created_at = new Date(document.created_at));

                    if (this.category.isGallery) {
                        this.documents = response.data.documents.filter(d => !this.isImage(d.mime_type));
                        this.images = response.data.documents.filter(d => this.isImage(d.mime_type));
                    } else {
                        this.documents = response.data.documents;
                    }

                    let b = [];
                    this.buildBreadcrumbsTree(this.categorySlug, b, {children: this.categoriesTree[0].children});
                    b.unshift({id: this.rootCategory.id, label: this.rootCategory.name, iconType: this.rootCategory.extra_data.icon_type, icon: this.rootCategory.extra_data.icon, to: `${compUrl}/${this.rootCategory.slug}`, replace: false});
                    this.$emit('updateBreadcrumbs', b);
                }).catch(error => {
                    console.error(error);
                    this.errorReportService.sendReport(
                        this.$appState.visitorId,
                        window.navigator.userAgent,
                        this.$appState.estate.id,
                        this.errorReportService.getRequestData(error.request),
                        this.errorReportService.getErrorData(error.response),
                    );
                    this.loading = false;
                    this.$toast.add({severity:'error', summary: 'Error', detail: 'D-01: No se ha podido obtener los documentos', life: 3000});
                });
            }
        },
        buildTree(a, p) {
            p.children.forEach(c => {
                let ch = {
                    key: c.id,
                    label: c.name,
                    data: {
                        id: c.id,
                        slug: c.slug,
                        name: c.name,
                        short_desc: c.short_desc,
                        extra_data: c.extra_data,
                        created_at: new Date(c.created_at)
                    },
                    children: []
                };

                if (c.children && c.children.length > 0) {
                    this.buildTree(ch.children, c);
                }

                a.push(ch);
            });
        },
        pageChanged(event) {
            this.page = event.page;
            this.fetchCategoryDocuments();
        },
        rowClick(event) {
            let ids = this.expandedRows.map(er => er.id);
            let i = ids.findIndex(id => id === event.data.id);

            if (i === -1) {
                ids.push(event.data.id);
            } else {
                ids.splice(i, 1);
            }

            this.expandedRows = this.documents.filter(d => ids.findIndex(id => id === d.id) > -1);
        },
        downloadUrl(id) {
            const token = window.sessionStorage.getItem('token');
            return `${ApiService.getHost()}/documents/${id}/download?_token=${token}`;
        },
        viewPdfUrl(id) {
            const token = window.sessionStorage.getItem('token');
            return `${ApiService.getHost()}/documents/${id}/view-pdf?_token=${token}`;
        },
        iconUri(icon, index) {
            return icon.split(':')[index];
        },
        isImage(mime_type) {
            return imageMimeTypes.includes(mime_type);
        },
        isPdf(mime_type) {
            return mime_type === 'application/pdf';
        },
        getIconImageUrl(id) {
            const token = window.sessionStorage.getItem('token');
            return `${ApiService.getHost()}/categories/${id}/icon-image?_token=${token}`;
        },
        goToCategory(slug) {
            window.history.pushState({}, '', `/categories/${slug}`);
            this.categorySlug = slug;
            this.fetchCategoryDocuments();
            let $contentList = document.querySelector('#content-list');

            if ($contentList) {
                window.scrollTo(0, $contentList.offsetTop);
            } else {
                window.scrollTo(0, 0);
            }
        },
        buildBreadcrumbsTree(s, b, c) {
            let f = false;

            c.children.forEach(cat => {
                if (cat.data.slug === s) {
                    f = true;
                    b.unshift({id: cat.data.id, label: cat.data.name, iconType: cat.data.extra_data.icon_type, icon: cat.data.extra_data.icon, to: `${compUrl}/${cat.data.slug}`, replace: false});
                    this.expandedTreeKeys[cat.data.id] = true;
                }

                if (!f && cat.children && cat.children.length > 0) {
                    f = this.buildBreadcrumbsTree(s, b, cat);

                    if (f) {
                        b.unshift({id: cat.data.id, label: cat.data.name, iconType: cat.data.extra_data.icon_type, icon: cat.data.extra_data.icon, to: `${compUrl}/${cat.data.slug}`, replace: false});
                        this.expandedTreeKeys[cat.data.id] = true;
                    }
                }
            });

            return f;
        },
        canDownload(documentExtraData) {
            if (documentExtraData && documentExtraData.disallow_download) {
                return false;
            }

            return true;
        },
    }
}
</script>

<style scoped lang="scss">
    .table-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width: 960px) {
            align-items: start;
        }
    }

    ::v-deep(.p-image-preview-container) {
        width: 100%;
    }
    ::v-deep(.galleryImg) {
        height: 140px;
        object-fit: cover;
        width: 100%;
    }
    ::v-deep(.p-image-action.p-link) {
        color: black;
    }

    ::v-deep(.p-tree .p-tree-container .p-treenode .p-treenode-content) {
        padding: 0.25rem 0.5rem;
    }
    ::v-deep(.p-tree .category-tree-node.current-category) {
        background-image: radial-gradient(circle at top left, var(--surface-200), transparent 150%);
        border: 1px solid var(--surface-hover);
        border-radius: var(--border-radius);
        font-weight: bold;
        padding: 0 6px;
    }

    @media screen and (min-width: 1024px) {
        ::v-deep(.p-tree) {
            height: 100%;
        }
    }

    @media screen and (max-width: 960px) {
        ::v-deep(.p-toolbar) {
            flex-wrap: wrap;
            
            .p-button {
                margin-bottom: 0.25rem;
            }
        }
    }
</style>
